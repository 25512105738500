import React from 'react'
import { Link } from 'gatsby'
import project1 from '../../assets/images/projects/project1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import project3 from '../../assets/images/projects/project3.jpg'
import project4 from '../../assets/images/projects/project4.jpg'
import project5 from '../../assets/images/projects/project5.jpg'
import project6 from '../../assets/images/projects/project6.jpg'

// Functional components
const TwoColumns = () => {
    return (
        <div className="projects-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project1} alt="about" />

                                <Link to="/case-studies-details-metadata-management" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/case-studies-details-metadata-management">
                                        Governance
                                    </Link>
                                </h3>
                                <span>Metadata Management</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project2} alt="about" />

                                <Link to="/case-studies-details-data-quality" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/case-studies-details-data-quality">
                                        Data Quality
                                    </Link>
                                </h3>
                                <span>Framework</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project3} alt="about" />

                                <Link to="/case-studies-details-rdm" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/case-studies-details-rdm">
                                        Reference Data Management
                                    </Link>
                                </h3>
                                <span>Metadata Management</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TwoColumns